import {
  Alert,
  AlertTypography,
  Button,
  Icon,
  ModalActionButton,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import { SpecialtyLogoMapper } from '../utils/SpecialtyLogoMapper';

interface AgentAdditionalInformationProps {
  agentSpecialties: string[];
  onClickChangeAgent: () => void;
  destinationActivityRecommendations: string;
  selectedDestinationName?: string;
}

function AgentAdditionalInformation({
  agentSpecialties,
  onClickChangeAgent,
  destinationActivityRecommendations,
  selectedDestinationName,
}: AgentAdditionalInformationProps) {
  return (
    <>
      <Stack
        direction={'column'}
        flexWrap={'wrap'}
        sx={{
          gap: { xs: 2, lg: 4 },
          padding: { xs: '20px 8px' },
          paddingLeft: {
            xs: '24px',
            md: '24px',
          },
          paddingRight: {
            xs: '24px',
            md: '24px',
          },
          paddingBottom: {
            xs: '13px',
            md: '13px',
          },
        }}
        data-testid='agentInfoBody'
      >
        <Stack direction={'column'} spacing={2}>
          <Stack
            direction={'row'}
            justifyContent={'flex-start'}
            data-testid='agentSpecializationHeader'
          >
            <Typography variant='b2Bold'>Our agents specialize in</Typography>
          </Stack>
          <Stack
            direction={'row'}
            flexWrap={'wrap'}
            gap={1}
            data-testid='agentSpecializations'
            sx={{
              '& .MuiButtonBase-root:disabled': {
                cursor: 'default',
                pointerEvents: 'auto',
                borderColor: (themes) => themes.palette.common.white,
                backgroundColor: (themes) => themes.palette.common.white,
                color: (themes) => themes.palette.grey[600],
              },
              '& .MuiSvgIcon-root': {
                color: (themes) => themes.palette.primary.main,
              },
            }}
          >
            {agentSpecialties?.map((specialty) => (
              <Button
                key={specialty}
                variant='outlined'
                label={specialty}
                size='medium'
                disabled={true}
                startIcon={SpecialtyLogoMapper[specialty]}
                rounded
              />
            ))}
          </Stack>
        </Stack>
        <Stack
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            textAlign: 'left',
          }}
          direction={'column'}
          flexWrap={'wrap'}
          spacing={1}
          data-testid='destinationRecommendations'
        >
          <Typography variant='b2Bold'>
            {selectedDestinationName} recommendations:
          </Typography>
          <Alert
            severity='success'
            displayCloseButton={false}
            icon={<Icon iconName='lightbulb-duotone' color='secondary' />}
          >
            <AlertTypography sx={{ textAlign: 'left' }}>
              {destinationActivityRecommendations}
            </AlertTypography>
          </Alert>
        </Stack>
        <ModalActionButton
          data-testid='pickDifferentAgentButton'
          label='Pick another agent'
          variant='text'
          startIcon={<Icon iconName='user-pen-duotone' />}
          sx={{
            alignSelf: 'flex-start',
            position: {
              xs: 'relative',
              lg: 'absolute',
            },
            bottom: '15px',
            left: {
              xs: 0,
              lg: '24px',
            },
            padding: '0',
          }}
          className='pickDifferentAgentButton'
          onClick={onClickChangeAgent}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              onClickChangeAgent();
            }
          }}
        />
      </Stack>
    </>
  );
}

export default AgentAdditionalInformation;
