import ProgressBar from '@/components/shared/ProgressBar';
import useScreenSize from '@/hooks/useScreenSize';
import useAppointmentStore from '@/store/AppointmentStore';
import { Box, Stack, Typography } from '@clublabs/shared-component-library';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import type { TimeSlot as TimeSlotInterface } from '../../appointments/utils/TimeSlot';
import { useScheduleAppointmentOutletContext } from '../routes/ScheduleAppointment';
import TimeSlot from './TimeSlot';

function AppointmentPicker() {
  // TODO: change useAppointmentsQuery to useAgentAppointmentQuery
  const {
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedAgent,
    setSelectedAgent,
    setSelectedDate,
    setSelectedStartTime,
    setSelectedEndTime,
  } = useAppointmentStore();

  useEffect(() => {
    const handleEnterKey = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const element = e.target as HTMLElement;
        if (element.getAttribute('aria-selected') == 'false') return;

        if (selectedDate && selectedStartTime && selectedEndTime) {
          onClickContinueHandler();
        }
      }
    };

    window.addEventListener('keydown', handleEnterKey);

    return () => {
      window.removeEventListener('keydown', handleEnterKey);
    };
  }, [selectedDate, selectedStartTime, selectedEndTime]);

  const [screenWidth, _] = useScreenSize();

  const { agentInfo, validateSelectedTimeExists } =
    useScheduleAppointmentOutletContext();

  const firstAvailableDate: TimeSlotInterface = agentInfo?.timeSlots[0];

  const filteredSlots: TimeSlotInterface[] = agentInfo?.timeSlots?.filter(
    (timeSlot: TimeSlotInterface) => {
      return (
        dayjs(timeSlot.startTime).format('MM/DD/YYYY').toString() ===
        dayjs(selectedDate?.toISOString() || firstAvailableDate?.startTime)
          .format('MM/DD/YYYY')
          .toString()
      );
    },
  );

  const filteredDates = new Set(
    agentInfo.timeSlots.map((timeSlot: TimeSlotInterface) => {
      return dayjs(timeSlot.startTime).format('MM/DD/YYYY').toString();
    }),
  );

  const storeAppointmentTimeSelection = (
    startTime: string,
    endTime: string,
  ) => {
    setSelectedStartTime(startTime);
    setSelectedEndTime(endTime);
  };
  const storeAppointmentDateSelection = (date: Date | undefined) => {
    if (date) {
      setSelectedDate(date);
      setSelectedStartTime('');
      setSelectedEndTime('');
    }
  };
  const onClickContinueHandler = () => {
    if (!selectedAgent.resourceId) setSelectedAgent(agentInfo);
    validateSelectedTimeExists(agentInfo);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: {
            lg: '100%',
            xl: '100%',
            xs: 'auto',
            md: 'auto',
          },
          justifyContent: 'space-between',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: {
              xs: 'auto',
              lg: '100%',
            },
          }}
          data-testid={'datePickerMainHeader'}
        >
          <Stack sx={{ marginBottom: '16px' }}>
            <Typography variant='h4'>
              Select date & time to speak with {agentInfo.name.split(' ')[0]}
            </Typography>
            <Typography variant='h6'>Book a call </Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            gap={3}
            sx={{
              paddingBottom: '20px',
              width: '100%',
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
              alignItems: {
                xs: 'center',
                lg: 'flex-start',
              },
            }}
          >
            <Box
              data-testid='datePicker'
              sx={{
                height: {
                  lg: '100%',
                  xl: '100%',
                  xs: 'auto',
                  md: 'auto',
                },
                padding: '3px 0',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  maxDate={dayjs().add(30, 'day')}
                  reduceAnimations
                  disableHighlightToday
                  disablePast
                  slotProps={{
                    actionBar: {
                      actions: [],
                    },
                    toolbar: {
                      hidden: true,
                    },
                    calendarHeader: {
                      sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '.MuiPickersCalendarHeader-switchViewIcon': {
                          display: 'none',
                        },
                        '.MuiPickersCalendarHeader-labelContainer': {
                          flexDirection: 'inherit',
                        },
                      },
                    },
                    switchViewIcon: {
                      display: 'none !important',
                    },
                  }}
                  sx={{
                    '& .MuiDateCalendar-root': {
                      height: 'auto',
                      mb: 0,
                    },
                    borderRadius: '6px',

                    boxShadow: '0px 2px 4px 0px rgba(8, 17, 34, 0.2)',
                    '& .MuiDayCalendar-weekDayLabel': {
                      margin: '0.1rem !important',
                    },
                    '& .MuiPickersDay-root:disabled': {
                      backgroundColor: (themes) => themes.palette.grey[200],
                      color: (themes) => themes.palette.grey[100],
                      borderRadius: 0,
                    },
                    '& .MuiPickersDay-root:disabled:hover': {
                      backgroundColor: (themes) => themes.palette.grey[200],
                    },
                    '& .MuiPickersDay-root': {
                      margin: '0.1rem !important',
                    },
                    '& .MuiButtonBase-root:disabled': {
                      cursor: 'not-allowed',
                      pointerEvents: 'auto',
                      color: (themes) => themes.palette.grey[100],
                    },
                    '& .MuiDayCalendar-slideTransition': {
                      minHeight: 'auto !important',
                    },
                    '& .MuiDayCalendar-monthContainer': {
                      position: 'relative !important',
                    },
                  }}
                  value={dayjs(
                    selectedDate || new Date(firstAvailableDate.startTime),
                  )}
                  defaultValue={dayjs(
                    selectedDate || new Date(firstAvailableDate.startTime),
                  )}
                  onChange={(value) => {
                    storeAppointmentDateSelection(value?.toDate());
                  }}
                  shouldDisableDate={(day) => {
                    return !filteredDates.has(
                      dayjs(day).format('MM/DD/YYYY').toString(),
                    );
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              data-testid='timeSlotContainer'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'center',
                width: '295px',
                maxWidth: { xs: '100%', lg: 'calc(100% - 24px - 391px)' },
                overflowY: 'scroll',
                flexWrap: 'nowrap',
                flexShrink: '0',
                maxHeight: {
                  lg: '407px',
                  xl: '407px',
                  xs: 'none',
                  md: 'none',
                },
                padding: '3px 0',
              }}
            >
              {filteredSlots.map((timeSlot: TimeSlotInterface) => (
                <TimeSlot
                  testId={timeSlot.startTime}
                  key={timeSlot.startTime}
                  onClickHandler={storeAppointmentTimeSelection.bind(
                    null,
                    timeSlot.startTime,
                    timeSlot.endTime,
                  )}
                  label={dayjs(timeSlot.startTime).format('hh:mma').toString()}
                  isSelected={selectedStartTime === timeSlot.startTime}
                />
              ))}
            </Box>
          </Stack>
        </Box>
        {(screenWidth === 'lg' || screenWidth === 'xl') && (
          <ProgressBar
            onClickContinue={onClickContinueHandler}
            buttonDisabled={
              !selectedStartTime?.length || !selectedDate?.toISOString().length
            }
            displayBackButton={true}
          />
        )}
      </Box>
      {(screenWidth === 'xs' || screenWidth === 'md') && (
        <ProgressBar
          onClickContinue={onClickContinueHandler}
          buttonDisabled={
            !selectedStartTime?.length || !selectedDate?.toISOString().length
          }
          displayBackButton={true}
        />
      )}
    </>
  );
}
export default AppointmentPicker;
