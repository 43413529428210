import { useEffect, useState } from 'react';

const convertToSCLBreakpoint = (
  screenSize: number,
): 'xs' | 'md' | 'lg' | 'xl' => {
  if (screenSize <= 601) return 'xs';
  if (screenSize <= 1024) return 'md';
  if (screenSize <= 1440) return 'lg';
  return 'xl';
};

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenSize]);

  return [convertToSCLBreakpoint(screenSize.width), screenSize.height];
};

export default useScreenSize;
