import ContactInfoForm from '@/components/shared/ContactInfoForm';
import SplitPageLayout from '@/components/shared/SplitPageLayout';
import { constants } from '@/constants';
import { postProcessEmailInquiry } from '@/features/confirmation/api/postCreateEmailLead';
import type { ProcessEmailInquiryData } from '@/features/confirmation/utils/ProcessEmailInquiryData';
import { DestinationImageMapper } from '@/features/destinations/utils/DestinationImageMapper';
import type { Region } from '@/features/destinations/utils/Region';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { Page, TaggingService, getClubCode } from '@/lib/tagging';
import { ErrorFallback } from '@/providers/app';
import useAppointmentStore from '@/store/AppointmentStore';

import ErrorPage from '@/components/shared/ErrorPage';
import LoadingContainer from '@/components/shared/LoadingContainer';
import cookieManager from '@/lib/cookieHelper';
import useProgressStore from '@/store/ProgressStore';
import useSessionStore from '@/store/SessionStore';
import useTSTStore from '@/store/TSTStore';
import {
  Box,
  Card,
  Icon,
  Stack,
  Typography,
  useEmailAddressValidator,
  usePhoneNumberValidator,
} from '@clublabs/shared-component-library';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

function EmailInquiry() {
  const navigateTo = useLandingPageRedirection();

  const { setProgress } = useProgressStore();

  const [isProcessingEmailInquiry, setIsProcessingEmailInquiry] =
    useState(false);
  const [processEmailInquiryDone, setProcessEmailInquiryDone] = useState(false);
  const [processEmailInquiryFailed, setProcessEmailInquiryFailed] =
    useState(false);
  const handleProcessEmailInquirySuccess = () => {
    setProgress(75);
    setProcessEmailInquiryDone(true);
  };

  const handleIsCreatingEmailInquiry = () => {
    setIsProcessingEmailInquiry(true);
  };

  const handleProcessEmailInquiryError = () => {
    setIsProcessingEmailInquiry(false);
    setProcessEmailInquiryFailed(true);
  };
  const navigate = useNavigate();

  const { sessionSource, sessionId, flowType } = useSessionStore();

  const {
    selectedDestination,
    contactInfo,
    setContactInfo,
    setConfirmationId,
  } = useAppointmentStore();

  const isPhoneNumberRequired = contactInfo.communicationPreference === 'Phone';
  const isEmailRequired = contactInfo.communicationPreference === 'Email';

  useEffect(() => {
    TaggingService.tag('view', sessionSource, {
      page: Page.contactInfo,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination.name,
    });
  }, []);

  const { TSTParams } = useTSTStore();

  const processEmailInquiry = useMutation({
    mutationFn: (emailData: ProcessEmailInquiryData) =>
      postProcessEmailInquiry(emailData),
    onSuccess: (responseData) => {
      setConfirmationId(responseData.incidentId);
      handleProcessEmailInquirySuccess();
      setTimeout(() => {
        navigate('/email-confirmation', { replace: true });
      }, 250);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR)
        handleProcessEmailInquiryError();
    },
  });

  const handleFormSubmit = () => {
    handleIsCreatingEmailInquiry();
    const emailData: ProcessEmailInquiryData = {
      clubCode: getClubCode(),
      destination: selectedDestination?.name ?? '',
      email: contactInfo.email ?? '',
      firstName: contactInfo.firstName ?? '',
      lastName: contactInfo.lastName ?? '',
      phone: contactInfo.phoneNumber ?? '',
      emailMessage: contactInfo.emailMessage ?? '',
      whenDoYouPlanToTravel: contactInfo.expectedTravelTime ?? '',
      zipCode: contactInfo.zipCode ?? '',
      typeOfTripDesired: contactInfo.typeOfTrip ?? [],
      AAAMemberFlag: !!window.aceMediaTagValues.custID,
      membershipNo: cookieManager('mbid') ?? '',
      trafficSource: window.aceMediaTagValues.traffic_source ?? '',
      trafficSourceCategory: window.aceMediaTagValues.traffic_category ?? '',
      websiteSource: window.aceMediaTagValues.website_source ?? '',
      MACSCustId: window.aceMediaTagValues.custID ?? '',
      TSTTripURL: TSTParams.TSTTripURL,
      preferredContactMethod: contactInfo.communicationPreference ?? '',
    };

    processEmailInquiry.mutate(emailData);
  };

  if (isProcessingEmailInquiry) {
    const radiosInfo = [
      {
        radioMessage: 'Gathering your information',
        isChecked: processEmailInquiryDone,
      },
    ];

    return (
      <LoadingContainer
        message={'Composing your email...'}
        radiosInfo={radiosInfo}
      />
    );
  }

  if (processEmailInquiryFailed) {
    return <ErrorPage onClickTryAgain={() => navigate(navigateTo)} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: {
            mobile: 'column',
            tablet: 'column',
            desktop: 'row',
          },
          height: '100%',
          width: '100%',
        }}
      >
        <SplitPageLayout
          leftSide={
            <Card
              sx={{
                marginTop: '20px',
                marginBottom: '20px',
                padding: '1.5rem 1rem 1rem 1rem',
                display: 'flex',
                flexDirection: 'column',
                gap: {
                  desktop: '20px',
                  mobile: '10px',
                },
                '&.MuiPaper-generic': {
                  width: 'auto',
                },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  mb: 2,
                }}
              >
                <img
                  src={
                    constants.IMAGE_BASE_URL +
                    DestinationImageMapper[selectedDestination?.name as Region]
                  }
                  alt={selectedDestination?.name}
                  style={{
                    width: '100%',
                    borderRadius: '0.5rem',
                  }}
                />
              </Box>
              <Stack
                direction='row'
                gap={'5px'}
                sx={{
                  width: '100%',
                }}
                alignItems='center'
              >
                <Icon iconName='map-location-dot-dualtone' color='primary' />
                <Typography variant='b2Bold'>Destination:</Typography>
                <Typography variant='b2Medium'>
                  {selectedDestination?.name}
                </Typography>
              </Stack>
            </Card>
          }
          rightSide={
            <ContactInfoForm
              formHeader='Please fill your contact info'
              fields={[
                'firstName',
                'lastName',
                'zipCode',
                'communicationPreference',
                'phoneNumber',
                'email',
                'expectedTravelTime',
                'typeOfTrip',
                'emailMessage',
              ]}
              formValues={contactInfo}
              updateField={setContactInfo}
              onSubmit={handleFormSubmit}
              isEmailFlow={true}
              fieldsProps={{
                phoneNumber: {
                  validator: usePhoneNumberValidator({
                    label: 'Phone Number',
                    required: isPhoneNumberRequired,
                  }),
                  label: isPhoneNumberRequired
                    ? 'Phone Number*'
                    : 'Phone Number',
                  key: `phoneNumber-${isPhoneNumberRequired}`,
                },
                email: {
                  validator: useEmailAddressValidator({
                    label: 'Email',
                    required: isEmailRequired,
                  }),
                  label: isEmailRequired ? 'Email*' : 'Email',
                  key: `email-${isEmailRequired}`,
                },
              }}
            />
          }
        />
      </Stack>
    </ErrorBoundary>
  );
}

export default EmailInquiry;
