import {
  AppHeader,
  Box,
  Container,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { ulid } from 'ulid';

import ErrorPage from '@/components/shared/ErrorPage';
import LoadingContainer from '@/components/shared/LoadingContainer';
import ProgressBar from '@/components/shared/ProgressBar';
import { constants } from '@/constants';
import { Page, TaggingService } from '@/lib/tagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';

import useProgressStore from '@/store/ProgressStore';
import useTSTStore from '@/store/TSTStore';
import { FlowType } from '@/types/FlowType';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDestinations } from '../api/getDestinations';
import DestinationCard from '../components/DestinationCard';
import type { Destination } from '../utils/Destination';
import { DestinationImageMapper } from '../utils/DestinationImageMapper';
import type { Region } from '../utils/Region';

const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
};
interface DestinationsProps {
  flow: FlowType;
}
function Destinations({ flow }: DestinationsProps) {
  const { isPending, isError, isSuccess, data, refetch, error } =
    useDestinations();

  const {
    selectedDestination,
    selectedAgent,
    setSelectedDestination,
    setSelectedDate,
    setSelectedStartTime,
    setSelectedEndTime,
    setSelectedAgent,
  } = useAppointmentStore();
  const {
    sessionId,
    setSessionId,
    setSessionSource,
    sessionSource,
    flowType,
    setFlowType,
  } = useSessionStore();

  const { setGeneratedRandomAgent, setRandomGeneratedAgentIndex } =
    useProgressStore();

  const location = useLocation();

  const { setTSTParams } = useTSTStore();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // https://travel.calif.aaa.com/cruise cruise has the redirection url query param as tstUrl
    // https://travel.calif.aaa.com/tour tour has the redirection url query param as tstURL
    const tstTripUrl = searchParams.get('tstUrl') || searchParams.get('tstURL');

    setTSTParams({
      TSTTripURL: tstTripUrl || '',
    });
  }, [setTSTParams, location.search]);

  const resetTimesIfDestinationChanged = (destinationWorkTypeId: string) => {
    if (selectedDestination?.workTypeId !== destinationWorkTypeId) {
      setSelectedStartTime('');
      setSelectedEndTime('');
      setSelectedDate(undefined);
    }
  };

  const resetAgentRandomizationIfDestinationChanged = (
    destinationWorkTypeId: string,
  ) => {
    if (selectedDestination?.workTypeId !== destinationWorkTypeId) {
      if (selectedAgent?.resourceId) setSelectedAgent({});
      setGeneratedRandomAgent(false);
      setRandomGeneratedAgentIndex(0);
    }
  };

  const handleDestinationSelection = (
    destinationWorkTypeId: string,
    destinationName: string,
  ) => {
    resetAgentRandomizationIfDestinationChanged(destinationWorkTypeId);
    resetTimesIfDestinationChanged(destinationWorkTypeId);
    setSelectedDestination({
      workTypeId: destinationWorkTypeId,
      name: destinationName,
    });
  };

  useEffect(() => {
    if (flow === FlowType.EMAIL) {
      setFlowType('emailInquiry');
    }
  }, [flow, setFlowType]);

  useEffect(() => {
    if (isSuccess && !isPending && !isError) {
      const newSessionId = ulid();
      if (!sessionId) {
        setSessionId(newSessionId);
      }
      setSessionSource('new');
      if (sessionSource) {
        TaggingService.tag('view', sessionSource || 'new', {
          page: Page.destination,
          sessionid: newSessionId,
          flow: flowType || '',
        });
      }
    }
  }, [
    isSuccess,
    isPending,
    isError,
    setSessionId,
    sessionId,
    setSessionSource,
    flowType,
    sessionSource,
  ]);

  const navigate = useNavigate();

  const handleOnClickContinue = () => {
    if (selectedDestination?.workTypeId) {
      if (flow === FlowType.EMAIL) {
        navigate(
          `/email-inquiry/${selectedDestination?.workTypeId}/contact-info`,
        );
      } else {
        navigate(`schedule-appointment/${selectedDestination?.workTypeId}`);
      }
    }
  };

  useEffect(() => {
    const handleEnterKey = (e: KeyboardEvent) => {
      if (selectedDestination && e.key === 'Enter') {
        handleOnClickContinue();
      }
    };

    window.addEventListener('keydown', handleEnterKey);

    return () => {
      window.removeEventListener('keydown', handleEnterKey);
    };
  }, [selectedDestination]);

  if (isPending || error?.message === constants.TANSTACK_ABORTED_ERROR) {
    const loadingMessage =
      flow === FlowType.EMAIL
        ? 'Your advisor app is loading...'
        : 'Your new adventure awaits...';

    return <LoadingContainer message={loadingMessage} showRadios={false} />;
  }

  if (isError && error.message !== constants.TANSTACK_ABORTED_ERROR) {
    return (
      <ErrorPage
        onClickTryAgain={() => {
          refetch();
        }}
      />
    );
  }

  if (data?.length) {
    return (
      <>
        <motion.article
          initial='hidden'
          animate='visible'
          exit={{ opacity: 0, transition: { duration: 1 } }}
          variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
        >
          <Box
            sx={{
              backgroundColor: (themes) => themes.palette.secondary.main,
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Stack
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              sx={{
                position: 'relative',
                width: '72px',
                height: '44px',
                maxHeight: '44px',
              }}
            >
              <motion.h1
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible,
                }}
                style={
                  {
                    '--base-width': '24vw',
                    letterSpacing: '-1.4vw',
                    margin: 0,
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                  } as any
                }
              >
                <AppHeader sx={{ marginTop: 0 }} />
              </motion.h1>
            </Stack>
            <Container maxWidth='md' data-testid='destinationsContainer'>
              <Stack
                direction={'column'}
                sx={{
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: '100px',
                  paddingTop: {
                    lg: '100px',
                    xs: '53px',
                  },
                }}
              >
                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: -20 },
                    visible,
                  }}
                  style={
                    {
                      top: '-18vw',
                      marginTop: 0,
                      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    } as any
                  }
                >
                  <Typography
                    variant='h4'
                    sx={{
                      px: {
                        xs: 0,
                        sm: 6,
                        md: 6,
                        lg: 8,
                      },
                      fontSize: {
                        xs: '1.5rem',
                        sm: '1.875rem',
                      },
                    }}
                  >
                    Tell me about what travel experience you would like to have?
                  </Typography>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    gap={4}
                    useFlexGap
                    sx={{
                      justifyContent: 'center',
                      '& .DestinationCard': {
                        transition: 'transform 0.2s',
                      },
                      '.selected': {
                        borderRadius: '12px',
                        outline: (themes) =>
                          `4px solid ${themes.palette.primary[300]}`,
                      },
                      mt: 3,
                    }}
                    data-testid='destinationsGrid'
                  >
                    {data!.map((destination: Destination) => (
                      <DestinationCard
                        key={destination.workTypeId}
                        destinationWorkTypeId={destination.workTypeId}
                        destinationName={destination.name}
                        imageUrl={`${
                          (constants.IMAGE_BASE_URL +
                            DestinationImageMapper[
                              destination.name as Region
                            ]) as string
                        }`}
                        label={destination.name}
                        onSelect={handleDestinationSelection}
                        isSelected={
                          selectedDestination?.workTypeId ===
                          destination.workTypeId
                        }
                      />
                    ))}
                  </Stack>
                </motion.div>
              </Stack>
            </Container>
          </Box>
        </motion.article>
        <ProgressBar
          onClickContinue={handleOnClickContinue}
          buttonDisabled={!selectedDestination?.workTypeId.length}
          fixed
          nextProgressValue={50}
          displayBackButton={false}
        />
      </>
    );
  }
}

export default Destinations;
