import ProfileImage from '@/components/shared/ProfileImage';
import { activitiesByRegionMapper } from '@/features/appointments/utils/ActivitiesByRegionMapper';
import { Region } from '@/features/destinations/utils/Region';
import useAppointmentStore from '@/store/AppointmentStore';
import {
  Box,
  Icon,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import { useEffect, useState } from 'react';
import AgentAdditionalInformation from './AgentAdditionalInformation';
import AgentInfoContactInfoPage from './AgentInfoContactInfoPage';

interface AgentInfoProps {
  agentName: string;
  agentLocation: string;
  agentImage: string;
  agentSpecialties: string[];
  onClickChangeAgent: () => void;
  isContactInfoPage: boolean;
}

function AgentInfo({
  agentName,
  agentLocation,
  agentImage,
  agentSpecialties,
  onClickChangeAgent,
  isContactInfoPage,
}: AgentInfoProps) {
  // default location to 'Online only' if no there are no physical locations
  const location = agentLocation ? `${agentLocation} branch` : 'Online only';
  const {
    selectedDestination,
    selectedDate,
    selectedStartTime,
    selectedEndTime,
  } = useAppointmentStore();
  const [
    destinationActivityRecommendations,
    setDestinationActivityRecommendations,
  ] = useState('');

  useEffect(() => {
    const region = Object.values(Region).find(
      (value) => value === selectedDestination?.name,
    ) as Region;
    if (region && activitiesByRegionMapper[region].length > 0) {
      const randomActivityIndex = Math.floor(
        Math.random() * activitiesByRegionMapper[region].length,
      );
      setDestinationActivityRecommendations(
        activitiesByRegionMapper[region][randomActivityIndex],
      );
    }
  }, [selectedDestination]);

  return (
    <>
      <Box
        data-testid='agentInfoHeader'
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'row',
            lg: 'column',
          },
          justifyContent: {
            xs: 'flex-start',
            lg: 'center',
          },
          alignItems: 'center',
        }}
      >
        <ProfileImage
          src={agentImage}
          alt='/travel/agent/scheduler/agentPlaceholder.png'
          size='responsive'
          testId='agentImage'
        />

        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={0}
          className={'agentInfoNameLocation'}
        >
          <Typography
            variant='h5'
            sx={{ padding: 1 }}
            className={'agentName'}
            data-testid='agentName'
          >
            {agentName}
          </Typography>
          <Stack
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            spacing={1}
            className={'agentLocation'}
            data-testid='agentLocation'
          >
            <Icon
              iconName='location-dot-duotone'
              color='primary'
              sx={{ width: '15px', height: '20px' }}
            />
            <Typography variant='b2Bold'>{location}</Typography>
          </Stack>
        </Stack>
      </Box>
      {!isContactInfoPage ? (
        <AgentAdditionalInformation
          onClickChangeAgent={onClickChangeAgent}
          agentSpecialties={agentSpecialties}
          destinationActivityRecommendations={
            destinationActivityRecommendations
          }
          selectedDestinationName={selectedDestination?.name}
        />
      ) : (
        <AgentInfoContactInfoPage
          agentName={agentName}
          selectedDate={selectedDate}
          selectedStartTime={selectedStartTime}
          selectedEndTime={selectedEndTime}
          selectedDestination={selectedDestination}
        />
      )}
    </>
  );
}

export default AgentInfo;
