import { useAppointmentsQuery } from '@/features/appointments/api/getAppointmentSlots';
import {
  Alert,
  Box,
  Modal,
  ModalCloseIconButton,
  ModalContent,
  ModalTitle,
} from '@clublabs/shared-component-library';
import { type QueryObserverResult, useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';

import ErrorPage from '@/components/shared/ErrorPage';
import LoadingContainer from '@/components/shared/LoadingContainer';
import { constants } from '@/constants';
import type { AgentInfo } from '@/features/appointments/utils/AgentInfo';
import { patchConfirmation } from '@/features/confirmation/api/patchConfirmation';
import type { ModifyAppointmentRequestData } from '@/features/confirmation/utils/ModifyAppointmentRequestData';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { CTA, Page, TaggingService, getClubCode } from '@/lib/tagging';
import { ErrorFallback } from '@/providers/app';
import useAppointmentStore from '@/store/AppointmentStore';
import useProgressStore from '@/store/ProgressStore';
import useSessionStore, { type FlowType } from '@/store/SessionStore';

import SplitPageLayout from '@/components/shared/SplitPageLayout';
import { queryClient } from '@/lib/queryClient';
import AgentInfoComponent from '../components/AgentInfo';
import NoAgentsFound from '../components/NoAgentsFound';
import PickDifferentAgentModalBody from '../components/PickDifferentAgentModalBody';
import type { ScheduleAppointmentOutletContext } from '../utils/OutletContext';
import type { TimeSlot as TimeSlotInterface } from '../utils/TimeSlot';
import { generateRandomAgentIndex } from '../utils/generateRandomAgentIndex';

function ScheduleAppointment() {
  const navigateTo = useLandingPageRedirection();
  const location = useLocation();
  const isRescheduleApt = location.pathname.includes('reschedule-appointment');

  const {
    appointmentId,
    selectedDestination,
    selectedAgent,
    selectedStartTime,
    selectedEndTime,
    selectedDate,
    confirmedAppointment,
    setSelectedAgent,
    setSelectedDate,
    setSelectedStartTime,
    setSelectedEndTime,
    setAppointmentId,
    setConfirmedAppointment,
  } = useAppointmentStore();

  const {
    isPending,
    data,
    isError,
    isSuccess,
    refetch,
    isRefetching,
    error,
    isLoading,
  } = useAppointmentsQuery(selectedDestination!.workTypeId, getClubCode());
  const updateAppointment = useMutation({
    mutationFn: (requestData: ModifyAppointmentRequestData) =>
      patchConfirmation(requestData),
    onSuccess: (data) => {
      handleCreateAppointmentDone();
      setAppointmentId(data?.appointmentId);
      setTimeout(() => {
        navigate('/confirmation');
      }, 250);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR)
        handleAppointmentUpdateError();
    },
  });
  const {
    setProgress,
    setGeneratedRandomAgent,
    generatedRandomAgent,
    setRandomGeneratedAgentIndex,
    randomGeneratedAgentIndex,
  } = useProgressStore();
  const { setFlowType, sessionSource, sessionId } = useSessionStore();

  const navigate = useNavigate();
  const { id } = useParams();

  const [isValidTime, setIsValidTime] = useState(true);

  const [openChangeAgentModal, setOpenChangeAgentModal] = useState(false);
  const [processCustomerAccountFailed, setProcessCustomerAccountFailed] =
    useState(false);
  const [processCustomerAccountDone, setProcessCustomerAccountDone] =
    useState(false);
  const [appointmentCreationFailed, setAppointmentCreationFailed] =
    useState(false);
  const [appointmentCreationDone, setAppointmentCreationDone] = useState(false);
  const [appointmentUpdateFailed, setAppointmentUpdateFailed] = useState(false);
  const [isValidatingTimeSlot, setIsValidatingTimeSlot] = useState(false);
  const [isCreatingAppointment, setIsCreatingAppointment] = useState(false);

  let flow: FlowType;
  if (isRescheduleApt) {
    flow = 'modify';
  } else {
    flow = '';
  }

  useEffect(() => {
    setFlowType(flow);
    if (!isPending && isSuccess && !isError) {
      TaggingService.tag('view', sessionSource, {
        page: Page.selectAppointment,
        sessionid: sessionId,
        flow: flow,
        trv_end_location: selectedDestination!.name,
      });
    }
  }, [isSuccess]);

  // fire tag when Select an agent modal is opened
  useEffect(() => {
    if (openChangeAgentModal) {
      TaggingService.tag('view', sessionSource, {
        page: Page.selectAgent,
        sessionid: sessionId,
        flow: flow,
        trv_end_location: selectedDestination!.name,
      });
    }
  }, [openChangeAgentModal]);

  useEffect(() => {
    if (isRescheduleApt && data?.length && !isCreatingAppointment) {
      let currentAgent = data.find(
        (agent: AgentInfo) =>
          agent.resourceId === confirmedAppointment?.agentInfo?.resourceId,
      ) as AgentInfo;

      if (!currentAgent) {
        const availableAgents = data.filter(
          (agent: AgentInfo) => agent.timeSlots.length > 0,
        );

        if (availableAgents.length > 0) {
          const randomIndex = generateRandomAgentIndex(
            availableAgents.length - 1,
          );
          currentAgent = availableAgents[randomIndex];
        } else {
          // Handle case where no agents have available time slots
          // For example, navigate to an error page or show a message
          return;
        }
      }
      setSelectedAgent(currentAgent);

      if (currentAgent?.timeSlots?.length > 0) {
        setSelectedDate(new Date(currentAgent.timeSlots[0].startTime));
      }

      setSelectedStartTime('');
      setSelectedEndTime('');

      return;
    }
  }, [isRescheduleApt, data, confirmedAppointment, isCreatingAppointment]);

  useEffect(() => {
    if (
      !isRescheduleApt &&
      data?.length &&
      !generatedRandomAgent &&
      !isPending &&
      !isRefetching
    ) {
      const randomlyGeneratedIndex = generateRandomAgentIndex(data.length - 1);
      setRandomGeneratedAgentIndex(randomlyGeneratedIndex);
      setGeneratedRandomAgent(true);

      const randomAgent = data[randomlyGeneratedIndex];
      setSelectedAgent(randomAgent);
      setSelectedDate(new Date(randomAgent.timeSlots[0].startTime));

      setSelectedStartTime('');
      setSelectedEndTime('');
    }
  }, [
    isRescheduleApt,
    data,
    generatedRandomAgent,
    randomGeneratedAgentIndex,
    isPending,
    isRefetching,
  ]);

  const changeAgentButtonClickedHandler = () => {
    setOpenChangeAgentModal(true);

    TaggingService.tag('link', sessionSource, {
      page: Page.selectAppointment,
      sessionid: sessionId,
      flow,
      trv_end_location: selectedDestination!.name,
      cta: CTA.pickAnotherAgent,
    });
  };

  const handleCloseModal = () => {
    setOpenChangeAgentModal(false);
  };

  const differentAgentSelectedHandler = (agentResourceId: string) => {
    const selectedAgent: AgentInfo = data?.find(
      (agent: AgentInfo) => agent.resourceId === agentResourceId,
    ) as AgentInfo;

    setSelectedAgent(selectedAgent);
    setSelectedDate(new Date(selectedAgent.timeSlots[0].startTime));
    setSelectedStartTime('');
    setSelectedEndTime('');

    setOpenChangeAgentModal(false);
  };

  const validateSelectedTimeExists = async (selectedAgentInfo: AgentInfo) => {
    if (isRescheduleApt) {
      handleIsCreatingAppointment();
    } else {
      setIsValidatingTimeSlot(true);
    }

    queryClient.removeQueries({ queryKey: ['timeSlots'] });

    refetch()
      .then((returnData: QueryObserverResult<AgentInfo[]>) => {
        const currentSelectedAgent: AgentInfo = returnData.data?.find(
          (agent: AgentInfo) =>
            agent.resourceId === selectedAgentInfo?.resourceId,
        ) as AgentInfo;

        const isSelectedStartTime: string | undefined =
          currentSelectedAgent?.timeSlots?.find(
            (timeSlot: TimeSlotInterface) =>
              timeSlot.startTime === selectedStartTime,
          )?.startTime;

        if (isSelectedStartTime !== undefined) {
          if (isRescheduleApt) {
            const updateAppointmentData: ModifyAppointmentRequestData = {
              agentRecordId: selectedAgent?.agentRecordId ?? '',
              agentResourceId: selectedAgent?.resourceId ?? '',
              appointmentId: appointmentId ?? '',
              serviceTerritoryId: selectedAgent?.serviceTerritoryId ?? '',
              startTime: selectedStartTime ?? '',
              endTime: selectedEndTime ?? '',
              status: 'Rescheduled',
            };
            handleProcessCustomerAccountDone();
            updateAppointment.mutate(updateAppointmentData);
          } else {
            setProgress(75);
            navigate(`${id}/contact-info`);
            setIsValidTime(true);
          }
        } else {
          if (isRescheduleApt) setIsCreatingAppointment(false);
          setIsValidTime(false);
          setSelectedStartTime('');
          setSelectedEndTime('');
          setSelectedDate(
            new Date(currentSelectedAgent.timeSlots[0].startTime),
          );
        }
      })
      .finally(() => setIsValidatingTimeSlot(false));
  };

  const handleProcessCustomerAccountError = () => {
    setIsCreatingAppointment(false);
    setProcessCustomerAccountFailed(true);
  };

  const handleProcessCustomerAccountDone = () => {
    setProcessCustomerAccountDone(true);
  };

  const handleAppointmentCreationError = () => {
    setIsCreatingAppointment(false);
    setAppointmentCreationFailed(true);
  };

  const handleCreateAppointmentDone = () => {
    setAppointmentCreationDone(true);
    setConfirmedAppointment({
      agentInfo: selectedAgent,
      selectedDate: selectedDate,
      selectedStartTime,
      selectedEndTime,
    });
  };

  const handleAppointmentUpdateError = () => {
    setIsCreatingAppointment(false);
    setAppointmentUpdateFailed(true);
  };

  const handleAgentInfoMappingToModal = useMemo(() => {
    if (data) {
      const currentlySelectedAgent: Partial<AgentInfo> =
        selectedAgent?.resourceId
          ? selectedAgent
          : data[randomGeneratedAgentIndex];
      return data.filter(
        (agent: Partial<AgentInfo>) =>
          agent.resourceId !== currentlySelectedAgent?.resourceId &&
          agent.timeSlots?.length,
      );
    }
  }, [
    data,
    randomGeneratedAgentIndex,
    selectedAgent,
    generatedRandomAgent,
  ]) as AgentInfo[];

  const handleIsCreatingAppointment = () => {
    setIsCreatingAppointment(true);
  };

  if (
    (isPending || isRefetching || isLoading) &&
    !isValidatingTimeSlot &&
    !isCreatingAppointment
  ) {
    const radiosInfo = [
      {
        radioMessage: 'Retrieving available time slots.',
        isChecked: true,
      },

      {
        radioMessage: 'Retrieving agent information.',
        isChecked: false,
      },
    ];

    return (
      <LoadingContainer
        message={
          isRescheduleApt
            ? `Hang tight while we're searching for the next available agents…`
            : `Hang tight while we're searching for your specialized agents…`
        }
        radiosInfo={radiosInfo}
      />
    );
  }

  if (!isRescheduleApt && (isRefetching || isValidatingTimeSlot)) {
    return (
      <LoadingContainer
        message={`Hang tight while we're confirming your date…`}
        radiosInfo={[
          { radioMessage: 'Verifying agent availability.', isChecked: true },
        ]}
      />
    );
  }

  if (isCreatingAppointment) {
    const radiosInfo = [
      {
        radioMessage: 'Gathering your information',
        isChecked: processCustomerAccountDone,
      },
      {
        radioMessage: 'Submitting your appointment',
        isChecked: appointmentCreationDone,
      },
    ];

    if (isRescheduleApt) {
      radiosInfo.unshift({
        radioMessage: 'Verifying agent availability.',
        isChecked: true,
      });
    }

    return (
      <LoadingContainer
        message={
          !isRescheduleApt
            ? 'It may take up to 30 seconds to confirm your appointment…'
            : 'It may take up to 30 seconds to reschedule your new appointment...'
        }
        radiosInfo={radiosInfo}
      />
    );
  }

  if (isError && error.message !== constants.TANSTACK_ABORTED_ERROR) {
    return <ErrorPage onClickTryAgain={refetch} />;
  }

  if (appointmentCreationFailed || processCustomerAccountFailed) {
    return <ErrorPage onClickTryAgain={() => navigate(navigateTo)} />;
  }

  if (appointmentUpdateFailed) {
    return (
      <ErrorPage
        onClickTryAgain={() => {
          window.location.replace(constants.APP_BASE_URL);
        }}
      />
    );
  }

  if ((data?.length === 0 || !data) && !isError) {
    return (
      <NoAgentsFound
        onClickTryAgain={() => {
          window.location.replace(constants.APP_BASE_URL);
        }}
      />
    );
  }

  if (data?.length) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <motion.div
          exit={{ opacity: 0, transition: { duration: 1 } }}
          variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
          style={{ width: '100%', height: '100%' }}
        >
          <Box
            data-testid='scheduleAppointmentContainer'
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'column',
                lg: 'row',
              },
              height: '100%',
              width: '100%',
            }}
          >
            <SplitPageLayout
              leftSide={
                <AgentInfoComponent
                  agentName={
                    selectedAgent?.name || data[randomGeneratedAgentIndex]?.name
                  }
                  agentLocation={
                    selectedAgent?.location ??
                    data[randomGeneratedAgentIndex]?.location
                  }
                  agentImage={
                    selectedAgent?.image ||
                    data[randomGeneratedAgentIndex]?.image
                  }
                  agentSpecialties={
                    selectedAgent?.agentSpecialties ||
                    data[randomGeneratedAgentIndex]?.agentSpecialties
                  }
                  onClickChangeAgent={changeAgentButtonClickedHandler}
                  isContactInfoPage={location.pathname.includes('contact-info')}
                />
              }
              rightSide={
                <>
                  <Alert
                    visible={!isValidTime}
                    severity={'error'}
                    sx={{
                      position: 'absolute',
                      top: '10%',
                      left: '50%',
                      transform: 'translate(-50%, -0%)',
                      zIndex: 2,
                      width: '80%',
                    }}
                    data-testid='invalidTimeSlotAlert'
                  >
                    That date & time has already been booked. Please select a
                    different time.
                  </Alert>
                  <Outlet
                    context={
                      {
                        isRescheduleApt,
                        agentInfo:
                          data.find(
                            (agent) =>
                              agent.resourceId === selectedAgent?.resourceId,
                          ) || data[randomGeneratedAgentIndex],
                        validateSelectedTimeExists,
                        handleAppointmentCreationError,
                        handleProcessCustomerAccountError,
                        handleIsCreatingAppointment,
                        handleProcessCustomerAccountDone,
                        handleCreateAppointmentDone,
                      } satisfies ScheduleAppointmentOutletContext
                    }
                  />
                </>
              }
            />
          </Box>
          <Modal
            open={openChangeAgentModal}
            onClose={handleCloseModal}
            size='medium'
            sx={{ '& .MuiPaper-root': { padding: 0, maxWidth: '100%' } }}
            variants={{
              xs: 'bottom-sheet',
              sm: 'regular',
            }}
            data-testid={'modal'}
          >
            <ModalTitle
              id='modal-title-1'
              sx={{ padding: '1.5rem', marginBottom: 0 }}
            >
              Select an agent
              <ModalCloseIconButton
                aria-label='Close'
                onClick={handleCloseModal}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                  }
                }}
              />
            </ModalTitle>
            <ModalContent id='modal-content-1'>
              <PickDifferentAgentModalBody
                selectDifferentAgentHandler={differentAgentSelectedHandler}
                agentsInfo={handleAgentInfoMappingToModal}
              />
            </ModalContent>
          </Modal>
        </motion.div>
      </ErrorBoundary>
    );
  }
}

export const useScheduleAppointmentOutletContext = () => {
  return useOutletContext<ScheduleAppointmentOutletContext>();
};

export default ScheduleAppointment;
