import type { ContactInfo } from '@/features/appointments/utils/ContactInfo';
import { getAceUserData } from '@/features/appointments/utils/getAceUserData';
import {
  Box,
  Checkbox,
  CheckboxTypography,
  Form,
  FormGrid,
  FormGridItem,
  FormTypography,
  Grid,
  MenuItem,
  Select,
  type SelectProps,
  Stack,
  TextField,
  type TextFieldProps,
  Typography,
  useEmailAddressValidator,
  useFirstNameValidator,
  useLastNameValidator,
  useMultilineTextValidator,
  usePhoneNumberValidator,
  useSelectValidator,
  useZipCodeValidator,
} from '@clublabs/shared-component-library';
import { useEffect, useMemo } from 'react';
import ProgressBar from './ProgressBar';

interface ContactInfoFormProps {
  fields: (keyof ContactInfo)[];
  updateField: (contactInfo: ContactInfo) => void;
  formValues: ContactInfo;
  onSubmit: (data: any) => void;
  formHeader: string;
  isEmailFlow?: boolean;
  fieldsProps?: {
    firstName?: TextFieldProps<'first-name'>;
    lastName?: TextFieldProps<'last-name'>;
    zipCode?: TextFieldProps<'zip-code'>;
    communicationPreference?: SelectProps;
    phoneNumber?: TextFieldProps<'phone-number'>;
    email?: TextFieldProps<'email-address'>;
    expectedTravelTime?: SelectProps;
    emailMessage?: TextFieldProps<'any-text'>;
    additionalInformation?: TextFieldProps<'any-text'>;
  };
}

function ContactInfoForm({
  fields,
  updateField,
  formValues,
  onSubmit,
  formHeader,
  isEmailFlow = false,
  fieldsProps,
}: ContactInfoFormProps) {
  const DEFAULT_WHEN_DO_YOU_PLAN_TO_TRAVEL: string = '0-3 Months';

  const multiLineInputStyles = {
    '& .MuiInputBase-root': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    '& .MuiTextField-root': {
      textAlign: 'left',
    },
  };

  const preferenceOptions = isEmailFlow
    ? ['Email', 'Phone']
    : ['Phone', 'Video Conference'];

  const fieldsMap: Record<keyof ContactInfo, () => JSX.Element> = {
    firstName: () => (
      <FormGridItem
        size={{
          xs: 12,
          lg: 6,
        }}
        key='firstName'
      >
        <TextField
          for='first-name'
          label='First name*'
          onChange={(e) =>
            updateField({ ...formValues, firstName: e.target.value })
          }
          value={formValues.firstName}
          validator={useFirstNameValidator({ label: 'First name' })}
          data-testid='firstName'
          {...fieldsProps?.firstName}
        />
      </FormGridItem>
    ),
    lastName: () => (
      <FormGridItem
        size={{
          xs: 12,
          lg: 6,
        }}
        key='lastName'
      >
        <TextField
          for='last-name'
          label='Last name*'
          onChange={(e) =>
            updateField({ ...formValues, lastName: e.target.value })
          }
          value={formValues.lastName}
          validator={useLastNameValidator({ label: 'Last name' })}
          data-testid='lastName'
          {...fieldsProps?.lastName}
        />
      </FormGridItem>
    ),
    zipCode: () => (
      <FormGridItem
        size={{
          xs: 12,
          lg: 6,
        }}
        key='zipCode'
      >
        <TextField
          for='zip-code'
          label='ZIP code*'
          onChange={(e) =>
            updateField({ ...formValues, zipCode: e.target.value })
          }
          validator={useZipCodeValidator({ label: 'ZIP code' })}
          value={formValues.zipCode}
          data-testid='zipCode'
          {...fieldsProps?.zipCode}
        />
      </FormGridItem>
    ),
    communicationPreference: () => (
      <FormGridItem
        size={{
          xs: 12,
          lg: 6,
        }}
        key='communicationPreference'
      >
        <Select
          label='Communication preference*'
          placeholder='Select one'
          onChange={(e) => {
            const newValue = e.target.value as
              | 'Phone'
              | 'Video Conference'
              | 'Email';
            if (!newValue) return;
            updateField({ ...formValues, communicationPreference: newValue });
          }}
          defaultValue={formValues.communicationPreference}
          validator={useSelectValidator({
            label: 'Communication preference',
          })}
          name='communicationPreference'
          fullWidth={true}
          data-testid='communicationPreference'
          {...fieldsProps?.communicationPreference}
        >
          {preferenceOptions.map((preference) => (
            <MenuItem key={preference} value={preference}>
              {preference}
            </MenuItem>
          ))}
        </Select>
      </FormGridItem>
    ),
    phoneNumber: () => (
      <FormGridItem
        size={{
          xs: 12,
          lg: 6,
        }}
        key='phoneNumber'
      >
        <TextField
          for='phone-number'
          label='Phone number*'
          onChange={(e) =>
            updateField({ ...formValues, phoneNumber: e.target.value })
          }
          validator={usePhoneNumberValidator({ label: 'Phone number' })}
          value={formValues.phoneNumber}
          data-testid='phoneNumber'
          {...fieldsProps?.phoneNumber}
        />
      </FormGridItem>
    ),
    email: () => (
      <FormGridItem
        size={{
          xs: 12,
          lg: 6,
        }}
        key='email'
      >
        <TextField
          for='email-address'
          label='Email*'
          onChange={(e) =>
            updateField({ ...formValues, email: e.target.value })
          }
          validator={useEmailAddressValidator({ label: 'Email' })}
          value={formValues.email}
          data-testid='email'
          {...fieldsProps?.email}
        />
      </FormGridItem>
    ),
    expectedTravelTime: () => (
      <FormGridItem
        size={{
          xs: 12,
          lg: 6,
        }}
        key='expectedTravelTime'
      >
        <Select
          label='When do you plan to travel'
          defaultValue={DEFAULT_WHEN_DO_YOU_PLAN_TO_TRAVEL}
          fullScreenThreshold={7}
          onChange={(e) =>
            updateField({ ...formValues, expectedTravelTime: e.target.value })
          }
          value={formValues.expectedTravelTime}
          name='expectedTravelTime'
          fullWidth={true}
          data-testid='expectedTravelTime'
          {...fieldsProps?.expectedTravelTime}
        >
          {[
            DEFAULT_WHEN_DO_YOU_PLAN_TO_TRAVEL,
            '3-6 Months',
            '6-9 Months',
            '9-12 Months',
            '12+ Months',
            'Not Sure',
          ].map((travelTime) => (
            <MenuItem key={travelTime} value={travelTime}>
              {travelTime}
            </MenuItem>
          ))}
        </Select>
      </FormGridItem>
    ),
    typeOfTrip: () => (
      <FormGridItem size={12} key='typeOfTrip'>
        <Typography variant='b2Regular'>Type of trip</Typography>
        <Grid
          container
          spacing={{ xs: 0, md: 1 }}
          columnSpacing={{ xs: 0, md: 1 }}
        >
          {[
            'Cruise',
            'Guided tour',
            'Hotel',
            'Flight',
            'Rental Car',
            'Build a Vacation Package',
            'Day Trips and Activities',
          ].map((tripType) => (
            <Grid
              size={{
                xs: 6,
                lg: 4,
              }}
              key={tripType}
              data-testid={`typeOfTrip-${tripType}`}
              sx={{
                pl: 0.5,
              }}
            >
              <Checkbox
                label={<CheckboxTypography>{tripType}</CheckboxTypography>}
                name={tripType}
                value='true'
                variant='text'
                onChange={({ name, checked }) => {
                  const currentValues = formValues.typeOfTrip || [];
                  if (checked) {
                    updateField({
                      ...formValues,
                      typeOfTrip: [...currentValues, name],
                    });
                  } else {
                    updateField({
                      ...formValues,
                      typeOfTrip: currentValues.filter((type) => type !== name),
                    });
                  }
                }}
                checked={formValues.typeOfTrip?.includes(tripType)}
              />
            </Grid>
          ))}
        </Grid>
      </FormGridItem>
    ),
    emailMessage: () => (
      <FormGridItem size={12} key='emailMessage'>
        <TextField
          name={'emailMessage'}
          data-testid='emailMessage'
          onChange={(e) =>
            updateField({ ...formValues, emailMessage: e.target.value })
          }
          value={formValues.emailMessage}
          multiline
          fullWidth
          label={'Email message*'}
          placeholder={''}
          validator={useMultilineTextValidator({
            label: 'Email message',
            required: true,
          })}
          sx={multiLineInputStyles}
          {...fieldsProps?.emailMessage}
        />
      </FormGridItem>
    ),
    additionalInformation: () => (
      <FormGridItem size={12} key='additionalInformation'>
        <TextField
          name={'additionalInformation'}
          data-testid='additionalInformation'
          onChange={(e) =>
            updateField({
              ...formValues,
              additionalInformation: e.target.value,
            })
          }
          value={formValues.additionalInformation}
          multiline
          fullWidth
          label={'Additional information'}
          placeholder={''}
          validator={useMultilineTextValidator({ required: false })}
          sx={multiLineInputStyles}
          {...fieldsProps?.additionalInformation}
        />
      </FormGridItem>
    ),
  };

  const aceUserData = useMemo(() => getAceUserData(), []);

  useEffect(() => {
    if (aceUserData && (!formValues.firstName || !formValues.lastName)) {
      updateField({
        ...formValues,
        firstName: aceUserData.firstName,
        lastName: aceUserData.lastName,
      });
    }
  }, [
    aceUserData,
    updateField,
    formValues,
    formValues.firstName,
    formValues.lastName,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        overflow: 'hidden',
      }}
    >
      <Form
        onSubmit={onSubmit}
        sx={{
          margin: 0,
          padding: 0,
          width: '100%',
          overflowY: 'scroll',
          maxWidth: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          '& .MuiIconButton-edgeEnd': {
            backgroundColor: 'white',
          },
          overflow: 'hidden',
        }}
        data-testid='contactInfoForm'
      >
        <Stack
          direction={'column'}
          alignItems={'center'}
          sx={{
            width: { lg: '65%', xs: '85%' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'auto',
            padding: '10px',
          }}
        >
          <FormTypography data-testid='contactInfoHeader'>
            {formHeader}
          </FormTypography>
          <FormGrid
            sx={{
              width: '100%',
              marginTop: '20px',
              textAlign: 'left',
              '& .MuiTextField-root': {
                alignItems: 'flex-start',
              },
              '& .MuiInputBase-input': {
                backgroundColor: 'rgba(255, 255, 255, 1)',
              },
            }}
          >
            {fields.map((field) => fieldsMap[field]())}
          </FormGrid>
        </Stack>
        <ProgressBar
          nextProgressValue={75}
          displayBackButton={true}
          isInsideForm
        />
      </Form>
    </Box>
  );
}

export default ContactInfoForm;
