import ProfileImage from '@/components/shared/ProfileImage';
import {
  Box,
  Button,
  Icon,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import dayjs from 'dayjs';

interface AgentInfoModalProps {
  id: string;
  name: string;
  image: string;
  location: string;
  nearestTimeSlot: string;
  selectAgentHandler: (agentId: string) => void;
}

function AgentInfoModal({
  id,
  name,
  image,
  location,
  nearestTimeSlot,
  selectAgentHandler,
}: AgentInfoModalProps) {
  const agentLocation = location ? `${location} branch` : 'Online only';
  return (
    <div
      onClick={() => selectAgentHandler(id)}
      data-testid='agentsInfoModalContainer'
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          selectAgentHandler(id);
        }
      }}
    >
      <Stack
        direction={'row'}
        gap={'10px'}
        sx={{
          padding: '1rem 1rem 1rem 1rem',
          ':hover': {
            cursor: 'pointer',
          },
          position: 'relative',
          backgroundColor: (themes) => themes.palette.secondary[300],
        }}
      >
        <Box data-testid='agentImageModal'>
          <ProfileImage
            src={image}
            alt='/travel/agent/scheduler/agentPlaceholder.png'
            size='small'
            testId='agentImageModal'
          />
        </Box>
        <Stack
          direction={{
            lg: 'row',
            xs: 'column',
            md: 'column',
          }}
          alignItems={'flex-start'}
          gap={{
            xs: '20px',
            md: '20px',
          }}
        >
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'flex-start'}
            gap={0}
            sx={{
              width: '100%',
            }}
          >
            <Typography variant='h5' data-testid='agentName'>
              {name}
            </Typography>
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              gap={1}
              data-testid='agentLocation'
            >
              <Icon
                iconName='location-dot-duotone'
                color='primary'
                sx={{ width: '15px', height: '20px' }}
              />
              <Typography variant='b2Bold'>{agentLocation}</Typography>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={{
                lg: 'center',
                xs: 'flex-start',
                md: 'flex-start',
              }}
              gap={1}
              data-testid='agentNextAvailableAppointment'
            >
              <Icon
                iconName='calendar-check-duotone'
                color='primary'
                sx={{ width: '15px', height: '20px' }}
              />
              <Stack
                direction={{
                  lg: 'row',
                  xs: 'column',
                  md: 'column',
                }}
              >
                <Typography variant='b1Medium'>
                  Next available appointment:{' '}
                </Typography>
                <Typography variant='b1Regular'>
                  {dayjs(nearestTimeSlot)
                    .format('MM/DD/YYYY - hh:mma')
                    .toString()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={'row'}
            gap={'5px'}
            sx={{
              '& .MuiButtonBase-root:disabled': {
                borderColor: (themes) => themes.palette.common.white,
                backgroundColor: (themes) => themes.palette.common.white,
                color: (themes) => themes.palette.grey[600],

                '& svg': {
                  fill: (themes) => themes.palette.primary.main,
                },
              },
              '& .MuiButtonBase-root:hover': {
                backgroundColor: 'inherit',
              },
              position: { xs: 'relative', lg: 'absolute' },
              right: { xs: '0', lg: '15px' },
              top: { xs: '0', lg: '15px' },
            }}
          >
            <Button
              key={'phone'}
              variant='outlined'
              label={'Phone'}
              size='medium'
              disabled={true}
              startIcon={<Icon iconName='phone-duotone' />}
              rounded
            />
            <Button
              key={'video'}
              variant='outlined'
              label={'Video'}
              size='medium'
              disabled={true}
              startIcon={<Icon iconName='video-regular' />}
              rounded
            />
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

export default AgentInfoModal;
