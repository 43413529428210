import { Box } from '@clublabs/shared-component-library';

interface ProfileImageProps {
  src?: string;
  alt: string;
  size: 'small' | 'responsive';
  testId?: string;
}

const ProfileImage = ({ src, alt, size, testId }: ProfileImageProps) => {
  const sizeMap = {
    small: {
      width: '80px',
      height: '80px',
    },
    responsive: {
      width: {
        small: '80px',
        xs: '80px',
        lg: '180px',
        xl: '180px',
      },
      height: {
        small: '80px',
        xs: '80px',
        lg: '180px',
        xl: '180px',
      },
    },
  };

  return (
    <Box
      sx={{
        width: sizeMap[size].width,
        height: sizeMap[size].height,
        borderRadius: '50%',
        overflow: 'hidden',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      }}
      data-testid={testId ? `profileImage-${testId}` : 'profileImage'}
    >
      <img
        src={src || '/travel/agent/scheduler/agentPlaceholder.png'}
        alt={alt}
        aria-label={'profile-image'}
      />
    </Box>
  );
};

export default ProfileImage;
