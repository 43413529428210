import { constants } from '@/constants';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { Page, TaggingService } from '@/lib/tagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import {
  AppHeader,
  Link as BaseLink,
  Box,
  Button,
  Card,
  Container,
  Icon,
  type LinkProps,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

interface EnhancedLinkProps extends LinkProps {
  testId?: string;
}

const Link: React.FC<EnhancedLinkProps> = ({
  children,
  sx,
  testId,
  ...props
}) => (
  <BaseLink
    {...props}
    variant='body1'
    sx={{
      ...sx,
    }}
    data-testid={testId}
  >
    {children}
  </BaseLink>
);

const EmailConfirmation = () => {
  useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname,
  );
  useLandingPageRedirection();

  const { sessionId, flowType, sessionSource } = useSessionStore();
  const { selectedDestination, confirmationId } = useAppointmentStore();

  useEffect(() => {
    TaggingService.tag('view', sessionSource, {
      page: Page.confirmation,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination.name,
      category: ['travel:scheduler'],
      confirmation_id: confirmationId,
      product: ['inquiry'],
      quantity: ['1'],
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#F7F8FA',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth='sm'>
        <Stack
          direction='column'
          alignItems='center'
          spacing={2}
          sx={{
            pb: 2,
          }}
        >
          <AppHeader data-testid='appHeader' />
          <Typography variant='h4' data-testid='emailConfirmationTitle'>
            Thank you
          </Typography>
          <Card
            sx={{
              width: '100%',
              padding: '1rem',
              boxSizing: 'border-box',
            }}
            data-testid='confirmationCard'
          >
            <Stack direction='column' spacing={2}>
              <Stack direction='row' alignItems='center' spacing={2}>
                <Icon
                  iconName='envelope-duotone'
                  color='primary'
                  sx={{
                    fontSize: '2rem',
                  }}
                  data-testid='icon-envelope-duotone'
                />
                <Typography
                  variant='h5'
                  align='left'
                  data-testid='requestSubmittedMessage'
                >
                  Your request has been submitted
                </Typography>
              </Stack>
              <Typography
                variant='body1'
                align='left'
                sx={{
                  fontWeight: 'normal',
                }}
                data-testid='confirmationMessage'
              >
                You will be contacted by a AAA Travel professional within the
                next 48 hours, or on our next business day if you submitted a
                request over the weekend. Did you know that AAA Travel has many
                other services? In addition to vacation packages, we also offer:
              </Typography>
              <Typography
                variant='body1'
                align='left'
                sx={{
                  fontWeight: 'normal',
                  padding: 0,
                  listStylePosition: 'inside',
                }}
                component='ul'
                data-testid='servicesList'
              >
                <Stack direction='column' spacing={1}>
                  <li data-testid='list-item-online-bookings'>
                    {'Air, car, hotel, cruise, and activities '}
                    <Link
                      href={constants.TRAVEL_HOME_PAGE_URL}
                      testId='link-travel-home-page'
                    >
                      online bookings
                    </Link>
                    {' options'}
                  </li>
                  <li data-testid='list-item-tourbook-guides'>
                    <Link
                      href={constants.TRAVEL_TOURBOOK_GUIDES_URL}
                      testId='link-travel-tourbook-guides-url'
                    >
                      TourBook® guides
                    </Link>
                    {' and '}
                    <Link
                      href={constants.DRIVING_DIRECTIONS_URL}
                      testId='link-driving-directions-url'
                    >
                      driving directions
                    </Link>
                  </li>
                  <li data-testid='list-item-passport-photos'>
                    <Link
                      href={constants.PASSPORT_PHOTOS_URL}
                      testId='link-passport-photos-url'
                    >
                      Passport photos
                    </Link>
                    {', '}
                    <Link
                      href={constants.DRIVING_PERMITS_URL}
                      testId='link-driving-permits-url'
                    >
                      international driving permits
                    </Link>
                    {' and '}
                    <Link
                      href={constants.FOREIGN_CURRENCY_URL}
                      testId='link-foreign-currency-url'
                    >
                      foreign currency
                    </Link>
                  </li>
                  <li data-testid='list-item-travel-insurance'>
                    <Link
                      href={constants.TRAVEL_INSURANCE_URL}
                      testId='link-travel-insurance-url'
                    >
                      Travel insurance
                    </Link>
                    {' through Allianz Global Assistance'}
                  </li>
                  <li data-testid='list-item-travel-articles'>
                    <Link
                      href={constants.TRAVEL_ARTICLES_URL}
                      testId='link-travel-articles-url'
                    >
                      Travel articles
                    </Link>
                    {' that feature tips, reviews, and destinations'}
                  </li>
                  <li data-testid='list-item-contact-aaa'>
                    {'To speak with AAA Travel Agent, visit '}
                    <Link
                      href={constants.AAA_BRANCH_URL}
                      testId='link-aaa-branch-url'
                    >
                      AAA branch
                    </Link>
                    {' or '}
                    <Link
                      href={constants.CONTACT_URL}
                      testId='link-contact-url'
                    >
                      contact us
                    </Link>
                  </li>
                </Stack>
              </Typography>
            </Stack>
          </Card>
          <Link
            sx={{
              textDecoration: 'none',
              width: '100%',
            }}
            href={constants.TRAVEL_HOME_PAGE_URL}
            testId='closePageLink'
          >
            <Button
              variant='contained'
              sx={{
                marginTop: '1rem',
                width: '100%',
              }}
              label='Close Page'
              data-testId='closePageButton'
            />
          </Link>
        </Stack>
      </Container>
    </Box>
  );
};

export default EmailConfirmation;
