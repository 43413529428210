import { AppHeader, Box, Stack } from '@clublabs/shared-component-library';
import type React from 'react';
import type { ReactNode } from 'react';

type SplitPageLayoutProps = {
  leftSide: ReactNode;
  rightSide: ReactNode;
};

const SplitPageLayout: React.FC<SplitPageLayoutProps> = ({
  leftSide,
  rightSide,
}) => {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
        height: '100%',
        width: '100%',
      }}
    >
      <AppHeader
        sx={{
          display: {
            lg: 'none',
            xl: 'none',
            md: 'block',
            xs: 'block',
          },
          backgroundColor: (themes) => themes.palette.grey[100],
        }}
      />
      <Stack
        direction={'column'}
        justifyContent={'center'}
        sx={{
          alignItems: 'center',
          px: {
            lg: '48px',
            xl: '48px',
            md: '0px',
            xs: '0px',
          },
          width: {
            xs: '100%',
            lg: '35%',
          },
          backgroundColor: (themes) => themes.palette.grey[100],
          height: '100%',
          position: 'relative',
        }}
      >
        {leftSide}
      </Stack>
      <Box
        sx={{
          width: {
            xs: '100%',
            lg: '65%',
          },
          backgroundColor: (themes) => themes.palette.secondary.main,
          height: {
            lg: '100%',
            xl: '100%',
            xs: 'auto',
            md: 'auto',
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <AppHeader
          className={'childContainerHeaderLogo'}
          sx={{
            display: {
              lg: 'block',
              xl: 'block',
              md: 'none',
              xs: 'none',
            },
          }}
        />
        {rightSide}
      </Box>
    </Stack>
  );
};

export default SplitPageLayout;
