import {
  AppHeader,
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Icon,
  Link as LinkScl,
  Modal,
  ModalCloseIconButton,
  ModalContent,
  ModalTitle,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  Link,
  useBlocker,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import './Confirmation.css';

import ErrorPage from '@/components/shared/ErrorPage';
import ProfileImage from '@/components/shared/ProfileImage';
import { constants } from '@/constants';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { Page, TaggingService } from '@/lib/tagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import { atcb_action } from 'add-to-calendar-button-react';
import { patchCancel } from '../api/patchCancel';
import CancelAppointmentModalBody from '../components/CancelAppointmentModalBody';
import type { Confirmation as ConfirmationInterface } from '../utils/Confirmation';
import type { ModifyAppointmentRequestData } from '../utils/ModifyAppointmentRequestData';
import { getEmailOption } from '../utils/getEmailOption';

interface EmailEvent {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  location: string;
  options: Array<
    | 'Google'
    | 'Apple'
    | 'iCal'
    | 'Microsoft365'
    | 'MicrosoftTeams'
    | 'Outlook.com'
    | 'Yahoo'
  >;
  timeZone: string;
  useUserTZ?: boolean;
}

function Confirmation() {
  useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes('reschedule-appointment') &&
      !nextLocation.pathname.includes('cancel-appointment'),
  );
  useLandingPageRedirection();

  const [searchParams, _] = useSearchParams();
  const emailFromParams: string | null | 'email' = searchParams.get('source');
  const [isCancellingAppointment, setIsCancellingAppointment] = useState(false);

  const {
    appointmentId,
    confirmationId,
    customerId,
    contactInfo,
    selectedDestination,
    confirmedAppointment,
  } = useAppointmentStore();

  const { sessionId, sessionSource, flowType, setFlowType } = useSessionStore();

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [appointmentCancelledFailed, setAppointmentCancelledFailed] =
    useState(false);
  const handleCloseModal = () => {
    setOpenCancelModal(false);
  };

  const navigate = useNavigate();

  const cancelAppointment = useMutation({
    mutationFn: (cancelAppointmentData: ModifyAppointmentRequestData) =>
      patchCancel(cancelAppointmentData),
    onSuccess: (data: ConfirmationInterface) => {
      setIsCancellingAppointment(false);
      navigate(`/cancel-appointment/${data.appointmentId}`);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR)
        setAppointmentCancelledFailed(true);
    },
  });

  const cancelAppointmentHandler = () => {
    setIsCancellingAppointment(true);
    setFlowType('cancel');
    const cancelAppointmentData: ModifyAppointmentRequestData = {
      agentResourceId: confirmedAppointment?.agentInfo?.resourceId ?? '',
      appointmentId: appointmentId ?? '',
      status: 'Canceled',
    };
    cancelAppointment.mutate(cancelAppointmentData);
  };

  useEffect(() => {
    const confirmationTags = {
      category: ['travel:scheduler'],
      confirmation_id: confirmationId,
      product: ['appointment'],
      quantity: ['1'],
      custID: customerId,
    };

    // if the user lands on the confirmation page through the email link, don't fire the confirmation tags OR
    // if they end up modifying the appointment even through email, we want to fire the confirmation tags
    const shouldPopulateConfirmationTags =
      sessionSource !== 'email' ||
      (sessionSource === 'email' && flowType === 'modify');

    TaggingService.tag('view', sessionSource, {
      page: Page.confirmation,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination!.name,
      comm_pref: contactInfo.communicationPreference,
      // for reporting reasons, we don't want these confirmation tags to be added if the source is email
      ...(shouldPopulateConfirmationTags ? confirmationTags : {}),
    });
  }, []);

  const clickAddToCalendarHandler = () => {
    const selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    const baseDate = dayjs(
      confirmedAppointment?.selectedDate ||
        new Date(confirmedAppointment?.selectedStartTime!),
    );

    const emailEvent: EmailEvent = {
      name: `Your appointment with ${confirmedAppointment?.agentInfo?.name}`,
      description: `We look forward to our ${selectedDestination?.name} call on ${baseDate.format('MMMM D')} at ${dayjs(confirmedAppointment?.selectedStartTime).format('h:mma')}!`,
      startDate: baseDate.format('YYYY-MM-DD'),
      startTime: dayjs(confirmedAppointment?.selectedStartTime).format('HH:mm'),
      endDate: dayjs(confirmedAppointment?.selectedEndTime).format(
        'YYYY-MM-DD',
      ),
      endTime: dayjs(confirmedAppointment?.selectedEndTime).format('HH:mm'),
      location: 'Online',
      useUserTZ: false,
      options: [contactInfo.email ? getEmailOption(contactInfo.email) : 'iCal'],
      timeZone: selectedTimezone,
    };

    atcb_action(emailEvent);
  };

  if (appointmentCancelledFailed) {
    return (
      <ErrorPage
        onClickTryAgain={() => {
          window.location.replace(`${constants.APP_BASE_URL}`);
        }}
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          backgroundColor: '#F7F8FA',
        }}
        data-testid='confirmationPage'
      >
        <AppHeader
          sx={{ position: 'absolute', top: 0 }}
          data-testid='appHeader'
        />
        <Box>
          <ProfileImage
            src={confirmedAppointment?.agentInfo?.image}
            alt='/travel/agent/scheduler/agentPlaceholder.png'
            size='responsive'
            testId='selectedAgentImage'
          />
        </Box>
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={0}
        >
          <Typography
            variant='h4'
            sx={{
              padding: 0,
              fontSize: { lg: '30px', xs: '24px' },
              marginTop: '10px',
            }}
            data-testid='agentMessage'
          >
            Looking forward to our call!
          </Typography>
        </Stack>
        <Stack
          direction={'column'}
          flexWrap={'wrap'}
          data-testid='callInfoCard'
          sx={{
            width: {
              lg: '40%',
              md: '40%',
              xs: '80%',
              xl: '20%',
            },
            textAlign: 'left',
          }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Card
            sx={{
              marginTop: '20px',
              marginBottom: '20px',
              padding: '1.5rem 1rem 1rem 1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: {
                lg: '20px',
                xs: '10px',
              },
              '&.MuiPaper-generic': {
                width: 'auto',
              },
            }}
          >
            <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
              <Icon
                iconName='calendar-check-duotone'
                color='primary'
                sx={{ width: '35px', height: '35px' }}
              />
              <Typography
                sx={{
                  fontWeight: '700',
                  fontSize: { xs: '16px', lg: '24px' },
                }}
              >
                30 minute call with{' '}
                {confirmedAppointment?.agentInfo?.name?.split(' ')[0]}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'baseline'}
              gap={'5px'}
              data-testid='dateAndTimeInfo'
            >
              <Typography
                variant='h6Medium'
                sx={{
                  margin: 0,
                  padding: 0,
                  textAlign: 'left',
                  fontSize: { xs: '16px' },
                  lineHeight: '20px',
                }}
              >
                When:{' '}
                <span style={{ fontWeight: 400 }}>
                  {confirmedAppointment?.selectedDate
                    ? dayjs(confirmedAppointment?.selectedDate).format(
                        'dddd, MMMM D',
                      )
                    : // biome-ignore lint/style/noNonNullAssertion: <explanation>
                      dayjs(
                        new Date(confirmedAppointment?.selectedStartTime!),
                      ).format('dddd, MMMM D')}{' '}
                  {confirmedAppointment?.selectedStartTime &&
                    confirmedAppointment?.selectedEndTime &&
                    `${dayjs(
                      new Date(confirmedAppointment?.selectedStartTime),
                    ).format(
                      'hh:mma',
                    )} - ${dayjs(new Date(confirmedAppointment?.selectedEndTime)).format('hh:mma')}`}
                </span>
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'baseline'}
              gap={'5px'}
              data-testid='destinationInfo'
            >
              <Typography
                variant='h6Medium'
                sx={{ margin: 0, padding: 0, fontSize: { xs: '16px' } }}
              >
                Destination:
              </Typography>
              <Typography
                variant='b1Regular'
                sx={{ margin: 0, padding: 0, fontSize: { xs: '16px' } }}
              >
                {selectedDestination?.name}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'baseline'}
              gap={'5px'}
              data-testid='nextStepsMessage'
            >
              {emailFromParams !== 'email' && (
                <Typography
                  variant='h6Medium'
                  sx={{
                    margin: 0,
                    padding: 0,
                    fontSize: { xs: '16px' },
                    lineHeight: '20px',
                  }}
                >
                  Next Steps:{' '}
                  <span style={{ fontWeight: 400 }}>
                    Keep an eye out for an email confirmation from me.
                  </span>
                </Typography>
              )}
            </Stack>
            <Stack
              direction={'row'}
              gap={2}
              sx={{ height: '25px', mt: '20px' }}
              alignItems={'center'}
              data-testid='modifyOrCancelButtonGroup'
            >
              <Link to={`/reschedule-appointment/${appointmentId}`}>
                <Button
                  variant='text'
                  label='Modify appointment'
                  sx={{ textDecoration: 'underline', padding: 0 }}
                />
              </Link>
              <Divider orientation='vertical' flexItem={true} />
              <Button
                variant='text'
                label='Cancel appointment'
                sx={{ textDecoration: 'underline', padding: 0 }}
                onClick={() => {
                  setOpenCancelModal(true);
                }}
              />
            </Stack>
          </Card>
          <ButtonGroup
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
            data-testid='addToCalendarOrCloseButtonGroup'
          >
            <Button
              label='Add to calendar'
              onClick={clickAddToCalendarHandler}
              fullWidth
              variant='outlined'
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
              }}
            />
            <LinkScl
              href={constants.TRAVEL_HOME_PAGE_URL}
              sx={{ width: '100%' }}
              data-testid='closePageButtonLink'
            >
              <Button label='Close page' fullWidth />
            </LinkScl>
          </ButtonGroup>
        </Stack>
      </Box>
      <Modal
        open={openCancelModal}
        onClose={handleCloseModal}
        size='medium'
        sx={{
          '& .MuiPaper-root': {
            padding: 0,
          },
          '& .MuiDialog-paper': {
            minHeight: 'auto',
          },
        }}
        variants={{
          xs: 'bottom-sheet',
          sm: 'regular',
        }}
        data-testid={'modal'}
      >
        <ModalTitle
          id='modal-title-1'
          sx={{ padding: '1.5rem', marginBottom: 0 }}
        >
          Are you sure?
          <ModalCloseIconButton aria-label='Close' onClick={handleCloseModal} />
        </ModalTitle>
        <ModalContent id='modal-content-1'>
          <CancelAppointmentModalBody
            onClickClose={handleCloseModal}
            onClickCancel={cancelAppointmentHandler}
            isCancellingAppointment={isCancellingAppointment}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

export default Confirmation;
